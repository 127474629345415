export const lengthCheck =(password:string) => {
  return password.length > 7
}

export const uppercaseCheck = (password: string) => {
  const regex = new RegExp('(?=.*[A-Z])')
  return regex.test(password)
}

export const lowercaseCheck = (password: string) => {
  const regex = new RegExp('(?=.*[a-z])')
  return regex.test(password)
}

export const digitCheck = (password: string) => {
  const regex = new RegExp('(?=.*[0-9])')
  return regex.test(password)
}

export const specialCharCheck = (password: string) => {
  const regex = new RegExp('([^A-Za-z0-9])')
  return regex.test(password)
}
export const repasswordCheck = (password: string, repassword: string) => {
  if(password=== '' || repassword===''){
    return false
  } 
  return password === repassword
}

export const checkStatus = (password:string): boolean => {
  let status = false;
  if (!lengthCheck(password)) {
    status = true
  }
  if (!uppercaseCheck(password)) {
    status = true
  }
  if (!lowercaseCheck(password)) {
    status = true
  }
  if (!digitCheck(password)) {
    status = true
  }
  if (!specialCharCheck(password)) {
    status = true
  }
  return status;
};

export const checkStatusExtend = (password:string,repassword:string): boolean => {
  let status = false;
  if (!lengthCheck(password)) {
    status = true
  }
  if (!uppercaseCheck(password)) {
    status = true
  }
  if (!lowercaseCheck(password)) {
    status = true
  }
  if (!digitCheck(password)) {
    status = true
  }
  if (!specialCharCheck(password)) {
    status = true
  }
  if (!repasswordCheck(password,repassword)) {
    status = true
  }
  return status;
};