import { IElementType, IReferenceElement } from "../definitions/element";
import { orderBy } from "lodash";
import { alphabet } from "../untils/const";
import { IAddtionalElements } from "../definitions/additional";
import { getElement } from "../components/Section/sectionModel";
import { SiteProps } from "../components/Section/Section";

export const veryfiedMoveCordinates = (
  value: number,
  max: number,
  min: number
): number => {
  let result = value;
  if (value < min) {
    result = min;
  } else if (value > max) {
    result = max;
  }
  return result;
};

export const getPoints = (
  curentState: SiteProps,
  elements: IElementType[],
  references: IReferenceElement[],
  pageNumber: number,
  sectionID: string
) => {
  if (elements.length === 0) {
    return elements;
  }
  const filtered = elements.filter(
    (element) => element.page === pageNumber && element.sectionID === sectionID
  );
  if (references.length > 0) {
    const filteredReference = references.filter(
      (element) =>
        element.page === pageNumber && element.sectionID === sectionID
    );
    filteredReference.forEach((ref) => {
      const sourceElement = getElement(ref.sourceElementID, elements);
      filtered.push({
        ...sourceElement,
        ...ref,
      });
    });
  }
  return filtered;
};
export const getPointsForList = (
  elements: IElementType[],
  sectionID: string,
  references: IReferenceElement[],
  showJointWeld: boolean
) => {
  const filtered = elements.filter(
    (element) => element.sectionID === sectionID
  );
  if (showJointWeld && references.length > 0) {
    const filteredReference = references.filter(
      (element) => element.sectionID === sectionID
    );
    filteredReference.forEach((ref) => {
      const sourceElement = getElement(ref.sourceElementID, elements);
      filtered.push({
        ...sourceElement,
        ...ref,
marked:true,
      });
    });
  }
  console.log(filtered)
  return filtered;
};

export const getMapElementForPage = (
  elements: IAddtionalElements[],
  pageNumber: number,
  sectionID: string
) => {
  if (elements === undefined) {
    return elements;
  }
  return elements.filter(
    (element) => element.page === pageNumber && element.sectionID === sectionID
  );
};

export const calculateCoordinates = (
  x: number,
  y: number,
  asc?: boolean
): number => {
  return x <= y ? (asc ? x : y) : asc ? y : x;
};

export interface ICord {
  min: number;
  max: number;
}
export const getCordinates = (pointCord: number, labelCord: number): ICord => {
  const pointStart = pointCord - 2;
  const pointEnd = pointCord + 2;
  const labelStart = pointCord + labelCord - 25;
  const labelEnd = pointCord + labelCord + 25;
  return {
    min: calculateCoordinates(pointStart, labelStart, true),
    max: calculateCoordinates(pointEnd, labelEnd),
  };
};
export interface IPointArea {
  topMin: number;
  topMax: number;
  leftMin: number;
  leftMax: number;
}
export const getPointsArea = (
  pointTop: number,
  pointLeft: number,
  labelTop: number,
  labelLeft: number
): IPointArea => {
  const topCord: ICord = getCordinates(pointTop, labelTop);
  const leftCord: ICord = getCordinates(pointLeft, labelLeft);
  const PointArea: IPointArea = {
    topMin: topCord.min,
    topMax: topCord.max,
    leftMin: leftCord.min,
    leftMax: leftCord.max,
  };
  return PointArea;
};

interface IValidatePosition {
  top: number;
  left: number;
}

export const validatePosition = (
  pointTop: number,
  pointLeft: number,
  labelTop: number,
  labelLeft: number,
  targetTopMin: number,
  targetTopMax: number,
  targetLeftMin: number,
  targetLeftMax: number,
  pointMode: boolean
): IValidatePosition => {
  const pointArea = getPointsArea(pointTop, pointLeft, labelTop, labelLeft);
  let validateTop = pointMode ? pointTop : labelTop;
  let validateLeft = pointMode ? pointLeft : labelLeft;
  const deltaTop = validateCoordinates(
    pointArea.topMin,
    pointArea.topMax,
    targetTopMin,
    targetTopMax
  );
  const deltaLeft = validateCoordinates(
    pointArea.leftMin,
    pointArea.leftMax,
    targetLeftMin,
    targetLeftMax
  );
  if (deltaTop.min !== 0) {
    validateTop = validateTop + deltaTop.min;
  }
  if (deltaTop.max !== 0) {
    validateTop = validateTop - deltaTop.max;
  }
  if (deltaLeft.min !== 0) {
    validateLeft = validateLeft + deltaLeft.min;
  }
  if (deltaLeft.max !== 0) {
    validateLeft = validateLeft - deltaLeft.max;
  }
  return {
    top: validateTop,
    left: validateLeft,
  };
};

interface ICordinatesValidator {
  min: number;
  max: number;
}

export const validateCoordinates = (
  moveMin: number,
  moveMax: number,
  targetMin: number,
  targetMax: number
): ICordinatesValidator => {
  const validators: ICordinatesValidator = {
    min: moveMin < targetMin ? Math.abs(targetMin - moveMin) : 0,
    max: moveMax > targetMax ? Math.abs(moveMax - targetMax) : 0,
  };

  return validators;
};

export const getLastPointName = (points: IElementType[]): string => {
  let name = "";
  if (points.length > 0) {
    name = orderBy(points, "createdDate")[points.length - 1].name;
  }
  return name;
};

export const getNamePrefix = (name: string): string => {
  let prefix = "";
  if (name !== "") {
    const splited = name.split(".");
    if (splited.length > 1) {
      prefix = splited[0] + ".";
    } else {
      let startPrefix = false;
      for (let j = name.length - 1; j >= 0; j--) {
        const check = isNaN(parseInt(name.charAt(j)));
        if (check) {
          startPrefix = true;
        }
        if (startPrefix) {
          prefix = name.charAt(j) + prefix;
        }
      }
    }
  }
  return prefix;
};

export const getScopeForPrefix = (
  pointsMap: string[],
  prefix: string
): string[] => {
  let numbers: string[] = [];

  if (prefix.length === 0) {
    numbers = pointsMap;
  } else {
    const isPrefixHaveDot = prefix.includes(".");
    pointsMap.forEach((item) => {
      const isItemHaveDot = item.includes(".");
      if (
        (isPrefixHaveDot && isItemHaveDot) ||
        (!isPrefixHaveDot && !isItemHaveDot)
      ) {
        if (item.startsWith(prefix)) {
          const itemName = item.replace(prefix, "");
          numbers.push(itemName);
        }
      } else if (!isPrefixHaveDot && isItemHaveDot) {
        const itemPrefix = item.split(".")[0];

        if (itemPrefix.startsWith(prefix)) {
          const itemName = itemPrefix.replace(prefix, "");
          numbers.push(itemName);
        }
      }
    });
  }

  return [...new Set(numbers)];
};

export const checkNumberName = (
  suffix: string,
  suffixArray: string[],
  number: number
): string => {
  const suffixLength = suffix.length;
  let numberCheck = number.toString().padStart(suffixLength, "0");

  if (suffixArray.includes(numberCheck)) {
    return checkNumberName(suffix, suffixArray, number + 1);
  }
  return numberCheck;
};

export const checkCharName = (
  suffix: string,
  suffixArray: string[],
  char: string
): string => {
  if (!suffixArray.includes(char)) {
    return char;
  }
  const charIndex = alphabet.findIndex((item) => item === char);
  if (charIndex !== alphabet.length - 1) {
    const nextChar = alphabet[charIndex + 1];
    return checkCharName(suffix, suffixArray, nextChar);
  }

  return "";
};

export const setNewSuffix = (suffix: string, sufixArray: string[]) => {
  let newSuffix = "";
  const checkIsNumber = isNaN(parseInt(suffix));

  if (!checkIsNumber) {
    newSuffix = checkNumberName(suffix, sufixArray, 1)!;
  } else {
    newSuffix = checkCharName(suffix, sufixArray, "a")!;
  }
  return newSuffix;
};

export const setNewPointName = (
  points: IElementType[],
  ref?: string
): string => {
  let name = ref ? ref : getLastPointName(points);
  if (name === "") {
    return name;
  }

  const pointsNames = points.map((i) => i.name);
  const isHaveDot = name.split(".").length > 1;
  const splited = name.split(".");

  let prePrefix = splited[0];
  if (splited.length > 1) {
    splited.pop();
    if (splited.length > 1) {
      prePrefix = splited.join(".");
    }
  }

  const prefix = isHaveDot ? prePrefix + "." : getNamePrefix(name);
  const suffix = name.replace(prefix, "");
  const sufixArray = getScopeForPrefix(pointsNames, prefix);
  const newName = prefix + setNewSuffix(suffix, sufixArray);

  return newName;
};
