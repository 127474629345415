import {
  IAddtionalCloud,
  IAddtionalDot,
  IAddtionalEdge,
  IAddtionalElements,
  IAddtionalTriage,
  getCloudForm,
  getDotForm,
  getEdgeForm,
  getTriageForm,
} from "./additional";
import { IElementFormType, IElementType } from "./element";
import {
  getFullJointForm,
  getJointForm,
  IJointType,
  jointValidatorFileds,
} from "./joint";

//ToDo refatro after finish
export const getFormDefinition = (
  type: string,
  data: IElementType | IAddtionalElements,
  isFullSpec: boolean
) => {
  if (type === "joint" && isFullSpec) {
    return getFullJointForm(data as IJointType);
  } else if (type === "joint") {
    return getJointForm(data as IJointType);
  } else if (type === "dot") {
    return getDotForm(data as IAddtionalDot);
  } else if (type === "triage") {
    return getTriageForm(data as IAddtionalTriage);
  } else if (type === "edge") {
    return getEdgeForm(data as IAddtionalEdge);
  } else if (type === "cloud") {
    return getCloudForm(data as IAddtionalCloud);
  }
};

export const jointValidator = (data: IElementFormType): boolean => {
  let isValid = true;

  for (const field of jointValidatorFileds) {
    const validationValue = data[field as keyof typeof data];

    if (typeof validationValue === "string" && validationValue.length === 0) {
      isValid = false;
    } else if (
      typeof validationValue === "number" &&
      (validationValue === 0 || validationValue === undefined)
    ) {
      isValid = false;
    }
  }

  return isValid;
};
