import React, { FC, useState } from "react";
import {
  IElementType,
  IExtendElementType,
  IReferenceElement,
  serializeElement,
} from "../../definitions/element";
import { Form, Modal,Text } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import {
  getFormDefinition,
  jointValidator,
} from "../../definitions/definitionHelper";
import { IJointFormType } from "../../definitions/joint";
import { ConnectedWeld } from "../ConnectedWeld/ConnectedWeld";
import { IAddtionalElements } from "../../definitions/additional";
import { ifHaveReferences, isReferencePoint } from "../../helpers/weldHelpers";
import { useStoreState } from "../../store";
import { setShowListPoints } from "../Section/sectionModel";

interface IPointModal {
  handleCancel: () => void;
  handleDelete: (id: string, isMapElement: boolean,isReference: boolean) => void;
  handleSave: (element: IElementType | IAddtionalElements,isSource:boolean) => void;
  elementID: string;
  element: IElementType;
  modalMode: string;
  predefineName?: string;
  handleAdd?: (element: IElementType) => void;
  handleAddReference?: (id: string) => void;
  fullSpec?:boolean
}
export const PointModal: FC<IPointModal> = ({
  handleCancel,
  handleDelete,
  handleSave,
  elementID,
  element,
  modalMode,
  predefineName,
  handleAdd,
  handleAddReference,
  fullSpec = false
}) => {

  const { references,elements,sections  } = useStoreState(
    (state) => state.projectDetails.projectDetails
  );
  const allElements = setShowListPoints(elements, sections)

  const syncName = (dataSet: IElementType) => {
    if (mode === "add" && dataSet.name === "") {
      dataSet.name = predefineName ? predefineName : "";
    }
    return dataSet;
  };

  const [mode, setMode] = useState(modalMode);
  const [existingJoint, setExistingJoint] = useState(false);
  const [selectedWeld, setSelectedWeld] = useState("");
  const [details, setDetails] = useState<IElementType>(syncName(element));

  const switchMode = () => {
    setSelectedWeld("");
    setDetails(syncName(element));
    setExistingJoint(!existingJoint);
  };
  const closeAction = () => {
    setMode("read");
    handleCancel();
  };

  const updateForm = (field: string, value: string | number) => {
    setDetails({ ...details, [field]: value });
  };

  const setTitle = () => {
    if (mode === "add") {
      return t(dictionary.newItem);
    } else if (mode === "read") {
      return `${t(dictionary.details)} ${details!.name}`;
    } else if (mode === "delete") {
      return `${t(dictionary.confirmDelete)} ${details!.name}`;
    } else {
      return `${t(dictionary.edition)}  ${details!.name}`;
    }
  };

  const setConfirmLabel = () => {
    if (mode === "add") {
      return t(dictionary.add);
    } else if (mode === "read") {
      return t(dictionary.edit);
    } else if (mode === "delete") {
      return t(dictionary.delete);
    } else {
      return t(dictionary.save);
    }
  };

  const setAdditionalLabel = () => {
    if (mode === "read") {
      return t(dictionary.delete);
    } else if (mode === "add") {
      return t(dictionary.existingJoint);
    }
    return undefined;
  };

  const setAdditionalAction = () => {
    if (mode === "read") {
      return setMode("delete");
    } else if (mode === "add") {
      return switchMode();
    } else {
      return undefined;
    }
  };
  const elementForm = {
    action: updateForm,
    data: getFormDefinition("joint", details,fullSpec)!,
  };

  const setConfirmAction = () => {
    if (mode === "add") {
      if (existingJoint && handleAddReference) {
        handleAddReference(selectedWeld);
      } else {
        if (jointValidator(details as IJointFormType) && handleAdd) {
          handleAdd(serializeElement(details as IJointFormType));
        }
      }
    } else if (mode === "read") {
      setMode("edit");
    } else if (mode === "edit") {

      handleSave(details,true);
    } else if (mode === "delete") {
      handleDelete(elementID, false,isReferencePoint(elementID, references));
    }
  };
  const validateSeclectedWeld = (value: string) => {
    return value.length === 0;
  };
  const setDisableValidator = (weldDetail: IElementType, selected: string) => {
    if (mode === "edit" || mode === "add") {
      return existingJoint
        ? validateSeclectedWeld(selected)
        : !jointValidator(weldDetail as IJointFormType);
    }
    return undefined;
  };

  const onSelect = (id: string) => {
    setSelectedWeld(id);
  };
  return (
    <Modal
      title={setTitle()}
      labelClose={t(dictionary.cancel)}
      labelConfirm={setConfirmLabel()}
      labelAdditional={setAdditionalLabel()}
      handleClose={closeAction}
      handleConfirm={setConfirmAction}
      handleAdditional={setAdditionalAction}
      size="medium"
      submitDisabled={setDisableValidator(details, selectedWeld)}
      additionalType={mode === "add" ? "toogle" : "button"}
      additionalValue={existingJoint}
    >
      <>
        {mode === "delete" ? (
          <Text>{ifHaveReferences(elementID, references)
            ? t(dictionary.doYouWantDeletePointWithReferences)
            : t(dictionary.doYouWantDeletePoint)}</Text>
        ) : existingJoint ? (
          <ConnectedWeld elements={allElements} onSelect={onSelect} />
        ) : (
          <Form formData={elementForm} read={mode === "read"} />
        )}
      </>
    </Modal>
  );
};
