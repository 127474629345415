import React, { FC } from "react";
import { Aligment, Card, Section, Text } from "venice-ui";
import { OrgDetailsPart } from "./OrganizationDetails.style";
import { useStoreState } from "../../store";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
export const OrganizationDetails: FC = () => {
  const { name, nameCode, creationTime } = useStoreState(
    (state) => state.organization
  );
  return (

      <Aligment direction="row" gap={10} vPadding="10PX">
        <OrgDetailsPart>
          <Text>Nazwa: {name}</Text>
        </OrgDetailsPart>
        <OrgDetailsPart>
          <Text>
            Aktywny od :{" "}
            {format(new Date(creationTime), "dd-MMM-yyyy", { locale: pl })}
          </Text>
          {/* <Text>Faktury</Text> */}
        </OrgDetailsPart>
      </Aligment>
  );
};
