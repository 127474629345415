import React, { FC, useState } from "react";
import { useStoreActions, useStoreState } from "../../store";
import { Aligment,  Table, TextSubHeader } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { EmptyState } from "../UI/EmptyState";
import {
  OrganizationDetailsWrapper,
  OrganizationsWrapper,
} from "./organizations.style";
import { MemberDetailsPanel } from "./MemberDetailsPanel";
import { IAction } from "venice-ui/dist/types/types";
import { RemoveMemberFromContractModal } from "../Modals/RemoveMemberFromContractModal";
import { AddContractToMember } from "../Modals/AddContractToMember";
import { apiCall } from "../../untils/apiCall";
import { getContractName } from "../../helpers/projectHelper";
import { UserModal } from "../Modals/UserModal";
import { roleScope } from "../../definitions/organizations";
import { ITableHeaderProps } from "../../definitions/ListDefinition";

export const OrganizationUsers: FC = () => {
  const {
    id: orgID,
    members,
    invitations,
    contracts,
  } = useStoreState((state) => state.organization);
  const { uid } = useStoreState((state) => state.user.userDetails);

  const [selectedMemberID, setSelectedMemberID] = useState("");

  const [removeContractDetails, setRemoveContractDetails] = useState({
    show: false,
    memberID: "",
    contractID: "",
  });

  const [assignModal, setAssignModal] = useState({
    show: false,
    memberID: "",
  });

  const [editModal, toogleEditModal] = useState({
    show: false,
    memberID: "",
  });
  const { addMemeberFromContract, removeInvitation } = useStoreActions(
    (actions) => actions.organization
  );

  const { addContractToUser } = useStoreActions((actions) => actions.user);

  const showAssignModal = (memberID?: string) => {
    if (memberID) {
      setAssignModal({
        show: true,
        memberID: memberID,
      });
    }
  };
  const hideAssignModal = () => {
    setAssignModal({
      show: false,
      memberID: "",
    });
  };
  const showEditModal = (id?: string) => {
    toogleEditModal({
      show: true,
      memberID: id!,
    });
  };
  const hideEditModal = () => {
    toogleEditModal({
      show: false,
      memberID: "",
    });
  };

  const showDeleteModal = (id?: string) => {
    console.log("showDeleteModal");
  };

  const hideDeleteModal = () => {
    console.log("hideDeleteModal");
  };

  const showUnassignedModal = (contractId?: string) => {
    if (contractId) {
      setRemoveContractDetails({
        show: true,
        memberID: selectedMemberID,
        contractID: contractId,
      });
    }
  };
  const hideUnassignedModal = () => {
    setRemoveContractDetails({
      show: false,
      memberID: "",
      contractID: "",
    });
  };

  const cancelInvitation = async (id?: string) => {
    const options = {
      invitationID: id,
    };
    await apiCall("organization/cancelInvitation", options, (response: any) => {
      removeInvitation(id!);
    });
  };

  const addContract = async (contractID: string, memberID: string) => {
    const options = {
      orgID: orgID,
      contractID: contractID,
      memberID: memberID,
      value: "ok",
    };
    await apiCall(
      "organization/toogleMemberInContract",
      options,
      (response: any) => {
        addMemeberFromContract({
          contractID: contractID,
          memberID: memberID,
        });
        if (uid === memberID) {
          addContractToUser({
            name: getContractName(contractID, contracts),
            id: contractID,
          });
        }
        hideAssignModal();
      }
    );
  };

  const usersHeaders: ITableHeaderProps[] = [
    {
      name: t(dictionary.email),
      valueSource: "email",
    },
    {
      name: t(dictionary.role),
      valueSource: "role",
      scope:roleScope
    },
  ];

  const moreActions: IAction[] = [
    {
      label: t(dictionary.addContractToMemeber),
      action: showAssignModal,
    },
    {
      label: t(dictionary.editUser),
      action: showEditModal,
      disableID: uid,
    },
    {
      label: t(dictionary.deleteUser),
      action: showDeleteModal,
      disableID: uid,
    },
  ];

  const invitationsHeaders: ITableHeaderProps[] = [
    {
      name: t(dictionary.email),
      valueSource: "user",
    },
    {
      name: t(dictionary.role),
      valueSource: "role",
      scope:roleScope
    },
    {
      name: t(dictionary.dateSent),
      valueSource: "date",
      date: true,
    },
  ];

  const moreInvitationsctions: IAction[] = [
    {
      label: t(dictionary.cancelInvitation),
      action: cancelInvitation,
    },
  ];
  return (
    <>
      {members.length > 0 ? (
        <Aligment>
          <OrganizationsWrapper>
            {invitations.length > 0 && (
              <>
                <TextSubHeader> {t(dictionary.invitationsSent)}</TextSubHeader>
                <Table
                  headers={invitationsHeaders}
                  elements={invitations}
                  sortable={false}
                  selectable={false}
                  moreActions={moreInvitationsctions}

                ></Table>
                <TextSubHeader> {t(dictionary.users)}</TextSubHeader>
              </>
            )}
            <Table
              headers={usersHeaders}
              elements={members}
              onRowClick={setSelectedMemberID}
              sortable={true}
              moreActions={moreActions}
            ></Table>
          </OrganizationsWrapper>
          <OrganizationDetailsWrapper>
            <MemberDetailsPanel
              memberID={selectedMemberID}
              handleUnassing={showUnassignedModal}
              handleAssing={showAssignModal}
              handleEdit={showEditModal}
            />
          </OrganizationDetailsWrapper>
        </Aligment>
      ) : (
        //ToDo dictionary
        <EmptyState title={"Nikogo nie ma"} />
      )}
      {removeContractDetails.show && (
        <RemoveMemberFromContractModal
          handleCancel={hideUnassignedModal}
          memberID={removeContractDetails.memberID}
          contractID={removeContractDetails.contractID}
        />
      )}
      {assignModal.show && (
        <AddContractToMember
          memberID={assignModal.memberID}
          handleCancel={hideAssignModal}
          handleConfirm={addContract}
        />
      )}
      {editModal.show && (
        <UserModal handleClose={hideEditModal} memberID={editModal.memberID} />
      )}
    </>
  );
};
