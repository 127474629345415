import React, { FC, useEffect, useRef, useState } from "react";
import { sectionListHeaders } from "../../definitions/ListDefinition";
import { ItemListContent, TableWrapper } from "./ItemList.styles";
import { Table } from "venice-ui";
import { useStoreActions, useStoreState } from "../../store";
import { getPointsForList } from "../../helpers/pdfHelpers";
import { PointModal } from "../PointModal/PointModal";
import {
  getElement,
  getReferenceElement,
  serializeSaveSourceElement,
  setOptionsSaveElement,
} from "../Section/sectionModel";
import { IElementType } from "../../definitions/element";
import { IAddtionalElements } from "../../definitions/additional";
import { useParams } from "react-router-dom";
import { apiCall } from "../../untils/apiCall";
import { IJointType } from "../../definitions/joint";

interface IItemList {
  showJointWeld: boolean;
}
export const ItemList: FC<IItemList> = ({ showJointWeld }: IItemList) => {
  const { sectionID, pageID, projectID } = useParams();
  const [tableHeight, setTableHeight] = useState(0);
  const { elements, references } = useStoreState(
    (state) => state.projectDetails.projectDetails
  );
  const { orgID } = useStoreState((state) => state.user.userDetails);

  const [pointDetails, updatePointDetails] = useState({
    show: false,
    id: "",
    mode: "read",
    isRefernce: false,
  });

  const { updateElement } = useStoreActions(
    (actions) => actions.projectDetails
  );

  const points = getPointsForList(
    elements,
    sectionID!,
    references,
    showJointWeld
  );
  const listRef = useRef<HTMLDivElement>(null);

  const handleClick = (id: string) => {
    const clicked = points.find((item) => item.id === id);
    updatePointDetails({
      show: true,
      id: id,
      mode: "read",
      isRefernce:
        "sourceElementID" in clicked! && clicked.sourceElementID !== undefined,
    });
  };

  const hidePointModal = () => {
    updatePointDetails({
      show: false,
      id: "",
      mode: "read",
      isRefernce: false,
    });
  };

  useEffect(() => {
    if (listRef.current) {
      setTableHeight(listRef.current.offsetTop);
    }
  }, [listRef]);

  const saveElement = async (element: IElementType | IAddtionalElements) => {
    const options = setOptionsSaveElement(
      element as IElementType,
      orgID,
      projectID!
    );
    await apiCall("projects/saveElement", options, (response: any) => {
      updateElement(element as IElementType);
      hidePointModal();
    });
  };

  const saveReference = async (element: IElementType | IAddtionalElements) => {
    const reference = element as IElementType;
    const sourceElement = getElement(
      reference.sourceElementID as string,
      elements
    );
    const referenceElement = serializeSaveSourceElement(
      element as IJointType,
      sourceElement as IJointType
    );
    saveElement(referenceElement as IElementType);
  };

  //ToDo zrovic tą funkcje
  const deleteElement = () => {};

  return (
    <>
      <TableWrapper id="list" ref={listRef} top={tableHeight}>
        <ItemListContent>
          <Table
            headers={sectionListHeaders}
            elements={points}
            onRowClick={handleClick}
            selectable={true}
            sortable={true}
          ></Table>
        </ItemListContent>
      </TableWrapper>
      {pointDetails.show && (
        <PointModal
          handleCancel={hidePointModal}
          elementID={pointDetails.id}
          element={
            pointDetails.isRefernce
              ? getReferenceElement(pointDetails.id, references, elements)
              : getElement(pointDetails.id, elements)
          }
          handleDelete={deleteElement}
          handleSave={pointDetails.isRefernce ? saveReference : saveElement}
          modalMode="read"
          fullSpec={true}
        />
      )}
    </>
  );
};
